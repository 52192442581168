import React, { useRef, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "./SearchInput.css";
import "./SearchResults.css";
import Placeholder from "../assets/AskUncle-original.png";

const SearchResults = ({ results }) => {
  const carouselRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const scrollWidth = 210;

  const scrollLeft = () => {
    if (currentIndex > 0) {
      setCurrentIndex(prevIndex => prevIndex - 1);
      carouselRef.current.scrollBy({ left: -scrollWidth, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (currentIndex < results.length - 1) {
      setCurrentIndex(prevIndex => prevIndex + 1);
      carouselRef.current.scrollBy({ left: scrollWidth, behavior: "smooth" });
    }
  };

  return (
    <div className="results-container-wrapper">
      <div className="carousel-controls left">
        <button
          className="carousel-button"
          onClick={scrollLeft}
          disabled={currentIndex === 0}
        >
          <FaChevronLeft size={16} />
        </button>
      </div>
      <div className="results-container" ref={carouselRef}>
        {results.map((result, index) => {
          const { profile, name, url, snippet } = result;
          const openGraphImage = result.openGraphImage?.contentUrl || Placeholder;

          // For the thumbnail section, always use the profile image.
          const thumbnailSrc = profile?.img || Placeholder;

          return (
            <a href={url} key={index} className="results-response" target="_blank" rel="noopener noreferrer">
              <div className="result-image">
                <img 
                  src={result.openGraphImage} 
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = Placeholder; // Fallback if image fails to load
                  }} 
                  className="result-image" 
                />
              </div>
              <div className="results-card">
                <div className="thumbnail-name-line">
                  <div className="result-image-thumbnail">
                    <img 
                      src={thumbnailSrc} 
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = Placeholder; // Fallback if image fails to load
                      }} 
                      className="result-thumbnail" // Optionally style the thumbnail separately
                    />
                  </div>
                  <p className="result-title">{name}</p>
                </div>
                <p className="result-url">{url}</p>
                <p 
                  className="result-snippet" 
                  dangerouslySetInnerHTML={{ __html: snippet }} 
                />
              </div>
            </a>
          );
        })}
      </div>
      <div className="carousel-controls right">
        <button
          className="carousel-button"
          onClick={scrollRight}
          disabled={currentIndex === results.length - 1}
        >
          <FaChevronRight size={16} />
        </button>
      </div>
    </div>
  );
};

export default SearchResults;
